import React from "react";
import {Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component, useRef } from 'react';
import Helmet from 'react-helmet';
import parse from 'html-react-parser'
import countryFile from './Country';
import Select from 'react-select'
import { ThreeDots } from 'react-loader-spinner'
import checkNumber from './CheckMobileNumber';

export default function CollegeDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const params = useParams();
    const [college, setCollege] = useState([])
	const [review, setPageReview] = useState([])
    const [collegerate, setCollegerate] = useState([])
    const [collegerates, setCollegerates] = useState([])
	const [notifications, setNotifications] = useState([])
    const [meta, setMeta] = useState('')
	const [countries, setCountry] = useState('')
    const [states, setStates] = useState([])
    const [stateValue, setStateValue] = useState([]);
	const [isLoading, setIsLoading] = useState(false)
    const mounted = useRef();
	const [mobile, setMobileValue] = useState([]);

	function selectCountry (event) {
		setStateValue([])
		const state_arr = countryFile.s_a[event.key].split("|"); 
		const stateArray = [];
		stateArray.push({
		  value: '', 
		  label: 'Select'
		});
		for (var i = 0; i < state_arr.length; i++) {
		  stateArray.push({
			value: state_arr[i], 
			label: state_arr[i]
		  });
		}
		setStates(stateArray)
		var Country = event.value;
		let check = checkNumber.checkNumber(Country,mobile);
		if(check === 2){
			setMobileValue('')
		}
	  }

	  function updateMobile(value){
		setMobileValue(value)
	  }
	  
	  function selectRegion (val) {
		if(val.value !== '' && val.value !== null){
		  setStateValue(val)
		} else {
		  setStateValue([])
		}
	  }

    useEffect(()=>{
        fetchProduct(params.id);
		const countryArray = [];
        // console.log(countryFile);
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        setCountry(countryArray);
    },[params.id])
    const fetchProduct = async () => {
		setIsLoading(true)
        await axios.get(`/college-page-data/${params.id}`).then(({data})=>{
			setCollege(data.college)
			setCollegerate(data.collegerate)
			setCollegerates(data.collegerates)
		    setNotifications(data.notifications)
		    setPageReview(data.pageReview)
          if(data.college.seo_meta){
            setMeta(data.college.seo_meta)
          } else {
            setMeta('<meta/>')
          }
		  setIsLoading(false)
        }).catch(({response:{data}})=>{
			setIsLoading(false)
          Swal.fire({
            text:'Error!.',
            icon:"error"
          })
        })
      }
     function blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      function reviewDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return date+ ' ' +month+' ' +year;
      }

      function enquirySubmit(event) {
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

      function submitReview(event){
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/post-college-review', formData)
        .then(({data})=>{
            event.target.reset()
			jQuery('#addReview').modal('hide');
			jQuery('body').removeClass('modal-open');
            fetchProduct()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'Please fill required fields.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }
  return (
	!isLoading ? 
	college!==null?
    	<div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                    <title>{college.seo_title}</title>
                    {parse(meta)}
					<meta property="og:image" content={`/storage/colleges/${college.image}`} />
					<meta property="og:image:secure_url" content={`/storage/colleges/${college.image}`} />
					<meta property="og:image:width" content="750" />
					<meta property="og:image:height" content="336" />
					<meta name="twitter:card" content="summary_large_image" />
					<meta name="twitter:description" content={college.seo_title} />
					<meta name="twitter:title" content={college.college} />
					<meta name="twitter:site" content="@collegesinbangalore" />
					<meta name="twitter:image" content={`/storage/colleges/${college.image}`} />
					<meta name="twitter:image:alt" content={college.college} />
					<meta name="twitter:creator" content="@collegesinbangalore" />
            </Helmet>
			<div className="modal fade" id="addReview" role="dialog">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<button type="button" className="close" data-dismiss="modal">&times;</button>
							<h6 className="modal-title">Submit your Rating & Review for {college.college}</h6>
						</div>
						<div className="modal-body">
								<div className="review-modal">
									<div className="row">
										<form onSubmit={submitReview} className="rat" id="reviewForm">
										<div className="col-xs-12">
											<label className="active">Click to rate:</label>
											  <span className="Please-Rate"></span>
											  <div className="dstar-rating">
												  <input type="radio" id="5-stars" name="star" value="5"/>
												  <label htmlFor="5-stars" className="star"><i className='fa fa-star fa-fw'></i></label>
												  <input type="radio" id="4-stars" name="star" value="4"/>
												  <label htmlFor="4-stars" className="star"><i className='fa fa-star fa-fw'></i></label>
												  <input type="radio" id="3-stars" name="star" value="3" />
												  <label htmlFor="3-stars" className="star"><i className='fa fa-star fa-fw'></i></label>
												  <input type="radio" id="2-stars" name="star" value="2" />
												  <label htmlFor="2-stars" className="star"><i className='fa fa-star fa-fw'></i></label>
												  <input type="radio" id="1-star" name="star" value="1" />
												  <label htmlFor="1-star" className="star"><i className='fa fa-star fa-fw'></i></label>
											  </div>
											</div>
											<div className="col-xs-12">
												<div className="text-block">
													{/* <!-- <label className="active">Write your review</label> --> */}
													<textarea rows="4" name="review" className="" placeholder="Write your review" required></textarea>
												</div>
											</div>
											<div className="col-xs-12">
												<div className="text-block">
													{/* <!-- <label>Name</label> --> */}
													<input type="text" name="name" placeholder="Enter your name" required/> 
												</div>
											</div>
											<div className="col-xs-12">
												<div className="text-block">
													{/* <!-- <label>Email</label> --> */}
													<input type="email" name="email" placeholder="Enter your Email" required/> </div>
													<input type="hidden" name="college" value={college.id}/>
											</div>
										
										<div className="col-xs-12 register-btn-box">
											<input className="btn submit Reviewsubmit" type="submit" value="Submit"/>
										</div>
										</form>
									</div>
								</div>
						</div>
					</div>
				</div>
			</div>
            <div id="content" className="site-content container">
                <div id="content-inner" className="site-content-inner row multi-columns-row">
					  <div id="primary" className="content-area col-md-8 col-lg-8 col-xs-12 sub">
						 <main id="main" className="site-main">
								<div className="row">
									<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
										<div className="vc_column-inner tm-responsive-custom-12751771">
											<div className="wpb_wrapper">
												<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
													<section className="tm-vc_cta3-container tm-sepcolor-default">
														<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
															<div className="tm-vc_cta3_content-container">
																<div className="tm-vc_cta3-content">
																	<header className="tm-vc_cta3-content-header tm-wrap">
																		<div className="tm-vc_cta3-headers tm-wrap-cell">
																			{(() => {
        																		if (college.h2_tag != null) {
																					return <h2 className="tm-custom-heading" style={{textTransform: 'uppercase',fontSize: '32px',lineHeight: '35px'}}>{college.h2_tag}</h2>
																				} else {
																					return <h2 className="tm-custom-heading" style={{textTransform: 'uppercase',fontSize: '32px',lineHeight: '35px'}}>{college.college}</h2>
																				}
																			})()}																			 </div>
																		<div className="heading-seperator"><span></span></div>
																	</header>
																	<div className="tm-cta3-content-wrapper bz-content">
																		<div className="college-img tm-featured-wrapper tm-lp_course-featured-wrapper">
                                                                            <img width="750" height="336" src={`/storage/colleges/${college.image}`} className="attachment-full size-full wp-post-image" alt={college.college}/>
                                                                        </div>
																		<div dangerouslySetInnerHTML={{ __html: college.overview }} />
																	</div>
																</div>
															</div>
														</div>
													</section>
												</div>
											</div>
										</div>
									</div>
								</div>

						 </main>
					  </div>
					  <aside id="sidebar-right" className="widget-area col-md-4 col-lg-4 col-xs-12 sidebar">
						<aside id="categories-5" className="rating widget-even widget-10 widget widget_categories">
							<h3 className="widget-title" style={{backgroundColor:'#131d3b'}}>{college.college} Rating & Reviews</h3>
							<div className="rating content">
								<div className="row rate-box" style={{marginBottom: 0}}>
									  <div className="col-sm-12">
											<div className="left-rate" style={{background: '#eee'}}>
                                                    <div className="overlay">
                                                        <h5 className="">Rating:</h5>
                                                        <h3> <span>{review.totalRating} </span><span>/5</span></h3>
                                                        <div className="">
                                                            <div className="rating-stars text-center">
                                                                <ul id="stars">
                                                                    <li className="star rate-this" title="1 star" data-value="1" style={{margin:0}}>
                                                                    <i className={`${review.totalRating >= 1 ? "fa fa-star fa-fw" : "fa fa-star-o fa-fw"}`}></i>
                                                                    </li>
                                                                    <li className="star rate-this" title="2 stars" data-value="2" style={{margin:0}}>
                                                                    <i className={`${review.totalRating >= 2 ? "fa fa-star fa-fw" : "fa fa-star-o fa-fw"}`}></i>
                                                                    </li>
                                                                    <li className="star rate-this" title="3 stars" data-value="3" style={{margin:0}}>
                                                                    <i className={`${review.totalRating >= 3 ? "fa fa-star fa-fw" : "fa fa-star-o fa-fw"}`}></i>
                                                                    </li>
                                                                    <li className="star rate-this" title="4 stars" data-value="4" style={{margin:0}}>
                                                                    <i className={`${review.totalRating >= 4 ? "fa fa-star fa-fw" : "fa fa-star-o fa-fw"}`}></i>
                                                                    </li>
                                                                    <li className="star rate-this" title="5 stars" data-value="5" style={{margin:0}}>
                                                                    <i className={`${review.totalRating >= 5 ? "fa fa-star fa-fw" : "fa fa-star-o fa-fw"}`}></i>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <p> Based on {review.counts} {review.counts == 1? 'review': 'reviews' }</p>
                                                        </div>
                                                        <hr className="collge-details-page-hr"/>
                                                        <button className="btn-review btn btn-success" data-toggle="modal" data-target="#addReview"><i className="fa fa-pencil-square" aria-hidden="true"></i> Write your review</button>
                                                    </div>
											</div>
									 </div>

                                        <div className="col-sm-12">
                                            <div className="recent-box text-left">
                                                <h5 className="last text-center">Reviews</h5>
												{collegerates && collegerates.length > 0 && collegerates.map((reviews, index) => {
													return (
														<div className="recent" key={`r${reviews.id}`}>
															<h6><span><div className="rating-stars">
																<ul id="">
																	<li className="star rate-this" title="1 star" data-value="1" style={{margin:0}}>
																	<i className={`${reviews.rating >= 1 ? "fa fa-star" : "fa fa-star-o"}`}></i>
																	</li>
																	<li className="star rate-this" title="2 stars" data-value="2" style={{margin:0}}>
																	<i className={`${reviews.rating >= 2 ? "fa fa-star" : "fa fa-star-o"}`}></i>
																	</li>
																	<li className="star rate-this" title="3 stars" data-value="3" style={{margin:0}}>
																	<i className={`${reviews.rating >= 3 ? "fa fa-star" : "fa fa-star-o"}`}></i>
																	</li>
																	<li className="star rate-this " title="4 stars" data-value="4" style={{margin:0}}>
																	<i className={`${reviews.rating >= 4 ? "fa fa-star" : "fa fa-star-o"}`}></i>
																	</li>
																	<li className="star rate-this " title="5 stars" data-value="5" style={{margin:0}}>
																	<i className={`${reviews.rating >= 5 ? "fa fa-star" : "fa fa-star-o"}`}></i>
																	</li>
																</ul> 
															</div></span> <span>|</span> {reviews.name} <span>|</span> <span className="date">{reviewDate(new Date(reviews.updated_at))}</span></h6>
															<p>{reviews.review}</p>
														</div>
													);
												})}
                                        	</div>
                                        </div>
						            </div>  
							</div>
						 </aside>
						 
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor: '26272a'}}>Apply now</h3>
							<div className="widget-content p-10 side-form">
								<form onSubmit={enquirySubmit} className="course-form clearfix">
									<div className="row">
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="name" placeholder="Name *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="email" name="email" placeholder="Email *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="phone" value={mobile} onChange={e => updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} className="EnquiryMobile" placeholder="Phone *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="course" placeholder="Course *" required/>
												<input type="hidden" name="source" value="2"/> </div>
										</div>
										<div className="col-xs-12 pad-5">
											<div className="text-block">
                          						<Select options={countries} name="country"  placeholder="Select Country" required onChange={selectCountry} />
											</div>
										</div>
                    					<div className="col-xs-12 pad-5">
											<div className="text-block">
                          						<Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={selectRegion} />
											</div>
										</div>
										<div className="col-xs-12 text-center">
											<input type="submit" value="Submit" className="btn submit"/> </div>
									</div>
								</form>
							</div>
						 </aside>
						 <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
							<a className="hover" href="/syllabus"><h3 className="widget-title" style={{backgroundColor:"#131d3b"}}>BPT Syllabus <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></h3></a>
						 </aside>
						 {(() => {
        				if (college.video !== '' && college.video !== null) {
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor: '#26272a'}}>College video</h3>
							<div className="vid-single">
								<a className="popup-youtube" href={`https://www.youtube.com/watch?v=${college.video}?rel=0`}>
								<div className="yt-box">
									<div className="youtube-player" data-id={college.video}></div>
								</div>
								</a>
							</div>
						 </aside>
						 }
						})()}
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor: '#479507'}}>Notifications</h3>
							<ul className="p-10 tm-recent-post-list scrollable-list">
								{notifications && notifications.length > 0 && notifications.map((notification, index) => {
									return (
									<li className="tm-recent-post-list-li" key={`n${notification.id}`}>
										<Link to={`/notifications/${notification.slug}`}>
											<img width="150" height="150" src={`/storage/notifications/${notification.image2}`} alt=""/>
										</Link>
										<Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
										<span className="post-date">{blogDate(new Date(notification.updated_at))}</span>
									</li>
									);
								})}
							</ul>
						 </aside>
						 
						 <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
							<a className="hover" href="https://bbacollegesinbangalore.com/" target="_blank"><h3 className="widget-title" style={{backgroundColor: '#134054'}}>Click to know about BBA Colleges in Bangalore <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></h3></a>
						 </aside>
					  </aside>
				   </div>      
                </div>
        </div>
	:
	<h1> Can't connect to server ! </h1>
	:
	<div className="row">
		<div className="col-md-12 text-center">
			<ThreeDots
			visible={true}
			height="100"
			width="100"
			color="#089400"
			radius="9"
			ariaLabel="three-dots-loading"
			wrapperStyle={{textAlign:'center'}}
			wrapperclassName="loader"
			/>
		</div>
  </div>
  )
};

// export default NotificationDetails;