import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';
import { HashLink } from 'react-router-hash-link';

export default class ContactUs extends Component {
    
    constructor(){
        super();
        this.state={
          blogs:[],
          notifications:[],
		  otherpages:[]
        }
      }
     
      async componentDidMount() {
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      async getUser(){
        let usersData=await axios.get('/contact-page-data')
                        .then(res=>{
                        //   return res.data;
                          this.setState({
                            blogs:res.data.blogs,
                            notifications:res.data.notifications,
							otherpages:res.data.otherpages
                          },()=>{
                            // console.log(this.state)
                          })
                        })
                        .catch(err=>{
                          console.log(err);
                        })
      }

	  blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      contactSubmit(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
		jQuery("#overlay").fadeIn(300);　
        axios.post('/store-contact-enuiry', formData)
        .then(({data})=>{
            event.target.reset()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
				setTimeout(function(){
                    jQuery("#overlay").fadeOut(300);
                },500);
			} else if(data.status === 201){
                Swal.fire({
                    text:'Please fill required fields.',
                    icon:"error"
                })
				setTimeout(function(){
                    jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
				setTimeout(function(){
                    jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

    render(){
        const {blogs}=this.state;
        const {notifications}=this.state;
		const {otherpages}=this.state;
        return (
          <div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                	<title>BPT Course Admission in Karnataka | Location details</title>
                	<meta name="description" content="Our Location: No. 309, 3rd Floor, Brigade Gardens, Church Street, MG Road, Bangalore Contact us for Direct BPT Admission in Top Colleges, +91 8095797575 / info@bptcollegesinbangalore.com"/>
					<meta name="keywords" content="Bpt direct admission, bpt colleges in Bangalore contact details, bpt colleges in Bangalore address, admission details for bpt"/>
            </Helmet>
              <div id="content" className="site-content container">
                    <div id="content-inner" className="site-content-inner row multi-columns-row">
					  <div id="primary" className="content-area col-md-8 col-lg-8 col-xs-12 sub">
						 <main id="main" className="site-main">
								<div className="row">
									<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
										<div className="vc_column-inner tm-responsive-custom-12751771">
											<div className="wpb_wrapper">
												<div className="contact-form">
												<h3>Get in touch</h3>
												<div className="widget-content">
													<form onSubmit={this.contactSubmit}>
														<div className="row">
															<div className="col-xs-12">
																<div className="text-block">
																	<input type="text" name="name" placeholder="Name *" required/> </div>
															</div>
															<div className="col-xs-12">
																<div className="text-block">
																	<input type="email" name="email" placeholder="Email *" required/> </div>
															</div>
															<div className="col-xs-12">
																<div className="text-block">
																	<input type="text" name="phone" placeholder="Phone *" required/> </div>
															</div>
															<div className="col-xs-12">
																<div className="text-block">
																	<input type="text" name="place" placeholder="Place *" required/>
															</div>
															</div>
															<div className="col-xs-12">
																<div className="text-block">
																	<textarea name="messages" placeholder="Message" required></textarea>
															</div>
															</div>
															<div className="col-xs-12 text-center">
																<input type="submit" value="Submit" className="btn submit"/> </div>
														</div>
													</form>
												</div>
												</div>
												<hr/>
												<div className="contact-info">
													<h3>Contact info</h3>
													<div className="contact-box">
														<div className="">
															<div className="col-sm-12 col-md-6 single-address-box">
																<div className="single-address">
																	<i className="fa fa-phone"></i>
																	<h5>Phone</h5>
																	<p>+91 8095797575</p>
																</div>
															</div>  
															<div className="col-sm-12 col-md-6  single-address-box">
																<div className="single-address">
																	<i className="fa fa-envelope"></i>
																	<h5>Email</h5>
																	<p>info@bptcollegesinbangalore.com</p>
																</div>
															</div> 
															<div className="col-sm-12 col-md-12 single-address-box">
																<div className="single-address">
																	<i className="fa fa-map-marker"></i>
																	<h5>Location:</h5>
																	<p>No. 309, 3rd Floor, Brigade Gardens, Church Street, MG Road, Bangalore, Karnataka, India - 560001</p>
																</div>
																
															</div> 
															
														<div className="col-sm-12 col-md-12 single-address-box">
															<div className="single-address">
																<i className="fa fa-map-o"></i>
																<h5>Map:</h5>
																<div className="map">
																	<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15551.833786905767!2d77.6053694!3d12.9745096!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x24f009752c3c427e!2sBPT%20Colleges%20In%20Bangalore%20-%20Physiotherapy%20Admission!5e0!3m2!1sen!2sin!4v1610356575619!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>
																</div>
															</div>
															
														</div>
														</div>
													</div> 
												</div>
											</div>
										</div>
									</div>
								</div>

						 </main>
					  </div>
					  
					  <aside id="sidebar-right" className="widget-area col-md-4 col-lg-4 col-xs-12 sidebar">
						<aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor: '#134054'}}>Blogs</h3>
							<ul className="p-10 tm-recent-post-list scrollable-list">
							{blogs && blogs.length > 0 && blogs.map((blog, index) => {
                                return (
                            	<li className="tm-recent-post-list-li" key={`b${blog.id}`}>
									<Link to={`/blogs/${blog.slug}`}>
										<img width="150" height="150" src={`/storage/blogs/${blog.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image" alt={blog.title}/>
									</Link>
                                    <Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
									<span className="post-date">{this.blogDate(new Date(blog.updated_at))}</span>
								</li>
								)
							})}
							</ul>
						 </aside>
						<aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							{otherpages && otherpages.length > 0 && otherpages.map((otherpage, index) => {
                                return (
							<Link to={`/other-pages/${otherpage.slug}`} key={`o${otherpage.id}`}><button className="btn-course" style={{marginBottom:"0"}}><i className="fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">{otherpage.title}</span> </button></Link>
								)
							})}
						</aside>
						<aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
							<HashLink className="hover" to='/syllabus#internship'><h3 className="widget-title" style={{backgroundColor:"#131d3b"}}>Internship <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></h3></HashLink>
						 </aside>
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title">Notifications</h3>
							<ul className="p-10 tm-recent-post-list scrollable-list">
							{notifications && notifications.length > 0 && notifications.map((notification, index) => {
                                return (
                                <li className="tm-recent-post-list-li" key={`n${notification.id}`}>
									<Link to={`/notifications/${notification.slug}`}>
                                        <img width="150" height="150" src={`/storage/notifications/${notification.image2}`} alt=""/>
                                    </Link>
                                    <Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
                                    <span className="post-date">{this.blogDate(new Date(notification.updated_at))}</span>
                                </li>
								);
							})}
							</ul>
						 </aside>
					  </aside>
				   </div>
              </div>
        </div>
        );
    }
}

// export default Home;