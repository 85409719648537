function checkNumber(Country, mobile){
  if(Country != 'India'){
    jQuery('.EnquiryMobile').attr('maxlength', 20);
    jQuery('.EnquiryMobile').attr('minlength', 1);
    jQuery('.EnquiryMobile').attr('placeholder', 'Phone');
    jQuery('.EnquiryMobile').removeClass('invalid');
    jQuery('.EnquiryMobile').addClass('valid');
    return 1;
  } else {
    jQuery('.EnquiryMobile').attr('maxlength', 10);
    jQuery('.EnquiryMobile').attr('minlength', 10);
    var phone = mobile.length;
    if(phone > 10){
        jQuery('.EnquiryMobile').attr('placeholder', 'Please enter 10 digit mobile number.');
        jQuery('.EnquiryMobile').removeClass('valid');
        jQuery('.EnquiryMobile').addClass('invalid');
        return 2;
    } else if(phone < 10) {
        jQuery('.EnquiryMobile').attr('placeholder', 'Please enter 10 digit mobile number.');
        jQuery('.EnquiryMobile').removeClass('valid');
        jQuery('.EnquiryMobile').addClass('invalid');
        return 3;
    } else {
        jQuery('.EnquiryMobile').attr('placeholder', 'Phone');
        jQuery('.EnquiryMobile').removeClass('invalid');
        jQuery('.EnquiryMobile').addClass('valid');
        return 4;
    }
  }
}

module.exports = {checkNumber};