import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import Helmet from 'react-helmet';
import { Audio } from 'react-loader-spinner'
import Select from 'react-select'
import countryFile from './Country';
import checkNumber from './CheckMobileNumber';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

export default class Home extends Component {

    constructor(){
        super();
        this.state={
          notifications:[],
          blogs:[],
          courses:[],
          countries: [], 
          states: [],
          stateValue:'',
          mobile:''
        }
      }
      
      selectCountry = (val) => {
        this.setState({ stateValue: '' });
        const state_arr = countryFile.s_a[val.key].split("|"); 
        const stateArray = [];
        stateArray.push({
          value: '', 
          label: 'Select'
        });
        for (var i = 0; i < state_arr.length; i++) {
          stateArray.push({
          value: state_arr[i], 
          label: state_arr[i]
          });
        }
        this.setState({ states: stateArray });
    
        var Country = val.value;
        let check = checkNumber.checkNumber(Country,this.state.mobile);
        if(check === 2){
          this.setState({mobile:''});
        }
      }
      
        selectRegion = (val) => {
          if(val.value !== '' && val.value !== null){
            this.setState({stateValue:val});
          } else {
            this.setState({stateValue:''});
          }
        }
        updateMobile = (value) => {
          this.setState({mobile:value});
        }
     
      async componentDidMount() {
        const countryArray = [];
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        this.setState({countries:countryArray});
        this.getList();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

      async getUser(){
        let usersData=await axios.get('/home-page-data')
            .then(res=>{
                this.setState({
                    notifications:res.data.notifications,
                    blogs:res.data.blogs,
                    courses:res.data.courses,
                    colleges:res.data.colleges
                },()=>{
                    // console.log(this.state)
                })
            })
            .catch(err=>{
                console.log(err);
            })
      }

      resetForm(){
        document.getElementById('home-page-middle-form').reset();
		// this.setState({mobile:''});
		jQuery('.EnquiryMobile').val('');
      }

	  truncate (str) {
		return str.length > 40 ? str.substring(0, 30) + ".." : str;
	  }

      handleSubmit(event) {
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            jQuery('#enquiry').modal('hide');
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
		
      }

    render(){
        const {notifications}=this.state;
        const {blogs}=this.state;
        const {courses}=this.state;
        const {colleges}=this.state;
        const myStyle = {
          textAlign:"left",
          fontSize:"24px",
          lineHeight:"37px",
          fontFamily:"'Poppins'",
          fontWeight:"400",
          color:"#fff !important" 
        }
const { countries, states, stateValue , mobile} = this.state;
return (
<div id="content-wrapper" className="site-content-wrapper">
<Helmet>
   <title>Top BPT Colleges in Bangalore | Physiotherapy Admission 2024</title>
   	<meta name="description" content="Apply for Direct BPT(Bachelor of Physiotherapy) Admission 2024 at Top BPT Colleges in Bangalore, listing best ranking BPT Colleges, Eligibility, Placements and notifications."/>
	<meta name="keywords" content="bpt colleges in bangalore, colleges in bangalore for bpt, physiotherapy admission bangalore, best bpt colleges  in bangalore, top physiotherapy colleges in bangalore."/>
</Helmet>
<div id="content" className="site-content ">
   <div id="content-inner" className="site-content-inner ">
	  <div id="primary" className="content-area">
		 <main id="main" className="site-main">
			<article id="post-2133" className="post-2133 page type-page status-publish hentry">
			   <header className="single-entry-header tm-hide">
				  <h2 className="entry-title">BPT Colleges</h2>
			   </header>
			   <div className="entry-content">
				  <div className="vc_row-full-width vc_clearfix"></div>
				  <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom hidden-sm hidden-md hidden-lg visible-xs">
					 <div className="vc_row container">
						<div className="wpb_column tm-column vc_column_container vc_col-sm-5 tm-zindex-0 margin-15px-991-colum">
						   <div className="vc_column-inner  ">
							  <div className="wpb_wrapper">
								 <div className="res-slider-form">
									<div className="widget">
									   <h4 className="widget-title text-center">Quick Enquiry</h4>
									   <p className="text-center">Apply now to get admission for BPT</p>
									   <div className="widget-content">
										  <form onSubmit={this.handleSubmit}>
											 <div className="row">
												<div className="col-xs-12">
												   <div className="text-block">
													  <input type="text" name="name" placeholder="Name *" required/> 
												   </div>
												</div>
												<div className="col-xs-12">
												   <div className="text-block">
													  <input type="email" name="email" placeholder="Email *" required/> 
												   </div>
												</div>
												<div className="col-xs-12">
												   <div className="text-block">
													  <input type="text" value={mobile} onChange={e => this.updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  className="EnquiryMobile" name="phone" placeholder="Phone *" required/> 
												   </div>
												</div>
												<div className="col-xs-12">
												   <div className="text-block">
													  <input type="text" name="course" placeholder="Course *" required/>
													  <input type="hidden" name="source" value="2"/> 
												   </div>
												</div>
												<div className="col-xs-12 pad-5">
												   <div className="text-block">
													  <Select options={countries} name="country"  placeholder="Select Country" required onChange={this.selectCountry} />
												   </div>
												</div>
												<div className="col-xs-12 pad-5">
												   <div className="text-block">
													  <Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={this.selectRegion} />
												   </div>
												</div>
												<div className="col-xs-12 text-center">
												   <input type="submit" value="Submit" className="btn submit"/> 
												</div>
											 </div>
										  </form>
									   </div>
									</div>
								 </div>
							  </div>
						   </div>
						</div>
					 </div>
				  </div>
				  <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
					 <div className="vc_row container">
						<div className="wpb_column tm-column vc_column_container vc_col-sm-5 tm-zindex-0 margin-15px-991-colum">
						   <div className="vc_column-inner  ">
							  <div className="wpb_wrapper">
								 <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid tm-responsive-custom-10018428 tm-zindex-0">
									<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 vc_col-has-fill tm-zindex-0">
									   <div className="vc_column-inner vc_custom_1567594802767 ">
										  <div className="wpb_wrapper">
											 <div className="wpb_single_image wpb_content_element vc_align_left  vc_custom_1567595065320">
												<figure className="wpb_wrapper vc_figure">
												   <div className="vc_single_image-wrapper vc_box_border_grey"><img src="/assets/img/about.jpg" className="vc_single_image-img attachment-full" alt="What is BPT?" /></div>
												</figure>
											 </div>
										  </div>
									   </div>
									</div>
								 </div>
							  </div>
						   </div>
						</div>
						<div className="wpb_column tm-column vc_column_container vc_col-sm-7 tm-zindex-0 margin-15px-991-colum">
							<div className="vc_column-inner tm-responsive-custom-12751771">
								<div className="wpb_wrapper">
									<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
										<section className="tm-vc_cta3-container tm-sepcolor-default">
											<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
												<div className="tm-vc_cta3_content-container">
													<div className="tm-vc_cta3-content">
														<header className="tm-vc_cta3-content-header tm-wrap">
															<div className="tm-vc_cta3-headers tm-wrap-cell">
																<h4 className="tm-custom-heading">What is</h4>
																<h3 className="tm-custom-heading ">Bachelor of <br className="hidden-xs"/>Physiotherapy (BPT)?</h3>
															</div>
															<div className="heading-seperator"><span></span></div>
														</header>
														<div className="tm-cta3-content-wrapper">
															<p>The Bachelor of Physiotherapy (BPT) course is an undergraduate professional course which is offered under the category of Allied Health Science. It is a course that deals with the science of physical movement and channelising it to prevent disability and diseases of movement. The study of physiotherapy or physical therapy helps the patient increase, maintain or restore their physical mobility, function and strength. This field makes use of electrotherapy, evidence-based kinesiology, exercise prescription and shockwave mobility to treat chronic conditions of the bones and soft tissues.</p>
															<p>Candidates are provided theoretical and practical exposure to various methods and techniques like exercise and massage to improve, strengthen and enhance flexibility. The course enhances the candidates in skills like:</p>
														</div>
													</div>
												</div>
											</div>
										</section>
									</div>
									<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
										<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-6 tm-zindex-0">
											<div className="vc_column-inner">
												<div className="wpb_wrapper">
													<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Analytical-thinking</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Strategy</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Decision-making</span></li>
													</ul>
												</div>
											</div>
										</div>
										<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-6 tm-zindex-0 margin-15px-991-colum">
											<div className="vc_column-inner  tm-responsive-custom-31923384">
												<div className="wpb_wrapper">
													<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591385700">
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Research</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Scientific Reasoning</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Patience</span></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
							<div className="vc_column-inner tm-responsive-custom-12751771">
								<div className="wpb_wrapper">
									<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
										<section className="tm-vc_cta3-container tm-sepcolor-default">
											<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
												<div className="tm-vc_cta3_content-container">
													<div className="tm-vc_cta3-content">
														<header className=" tm-wrap">
															<div className="tm-vc_cta3-headers tm-wrap-cell">
																<h5 className="tm-custom-heading" style={{marginTop:"20px"}}>The <b>BPT course in Bangalore</b> offers student in-depth knowledge on the following topics:</h5>
															</div>
														</header>
													</div>
												</div>
											</div>
										</section>
									</div>
									<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
										<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-3 tm-zindex-0">
											<div className="vc_column-inner  ">
												<div className="wpb_wrapper">
													<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Anatomy</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Biochemistry</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Biomechanics</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Biostatistics</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Cardio-respiratory</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Community Medicine</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Community Physiotherapy</span></li>
													</ul>
												</div>
											</div>
										</div>
										<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-3 tm-zindex-0 margin-15px-991-colum">
											<div className="vc_column-inner  tm-responsive-custom-31923384">
												<div className="wpb_wrapper">
													<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591385700">
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Electrotherapy</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Exercise Therapy</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">General Medicine</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">General Surgery</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Microbiology</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Musculoskeletal</span></li>
													</ul>
												</div>
											</div>
										</div>
										<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-3 tm-zindex-0 margin-15px-991-colum">
											<div className="vc_column-inner  tm-responsive-custom-31923384">
												<div className="wpb_wrapper">
													<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591385700">
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Neurology</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Neuro Physiotherapy</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Neurosurgery</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Orthopaedics</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Pathology</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Physiology</span></li>
													</ul>
												</div>
											</div>
										</div>
										<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-3 tm-zindex-0 margin-15px-991-colum">
											<div className="vc_column-inner  tm-responsive-custom-31923384">
												<div className="wpb_wrapper">
													<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591385700">
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Physiotherapy</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Psychology</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Research Methodology</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Sociology</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Sports Physiotherapy</span></li>
														<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Traumatology</span></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
   							 <div className="vc_column-inner tm-responsive-custom-12751771">
                				<div className="wpb_wrapper">
           							 <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default"><section className="tm-vc_cta3-container tm-sepcolor-default"><div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading"><div className="tm-vc_cta3_content-container"><div className="tm-vc_cta3-content">
                						<header className=" tm-wrap">
											<div className="tm-vc_cta3-headers tm-wrap-cell">
                							<h5 className="tm-custom-heading" style={{marginTop:"20px"}}>Duration</h5>
                								<p>The <b>Bachelor of Physiotherapy (BPT) course in Bangalore</b> is a 4-years undergraduate course with 6-months of mandatory internship programme.</p>
											</div>
											</header>
										</div></div></div></section></div>
        						</div>
    							</div>
							</div>
						</div>
						<div className="vc_row container">  
							<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
								<div className="vc_column-inner tm-responsive-custom-12751771">
									<div className="wpb_wrapper">
										<hr/>
										<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
											<section className="tm-vc_cta3-container tm-sepcolor-default">
												<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
													<div className="tm-vc_cta3_content-container">
														<div className="tm-vc_cta3-content">
															<header className="tm-vc_cta3-content-header tm-wrap">
																<div className="tm-vc_cta3-headers tm-wrap-cell">
																	<h5 className="tm-custom-heading">Top BPT colleges in Bangalore</h5>
																</div>
																<div className="heading-seperator"><span></span></div>
															</header>
														</div>
													</div>
												</div>
											</section>
										</div>
										<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
											<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
												<div className="vc_column-inner  ">
													<div className="wpb_wrapper">
														<p>The <b>list of best physiotherapy colleges in Bangalore</b> are:</p>
													</div>
												</div>
											</div>
											{colleges && colleges.length > 0 && colleges.map((collegeloop, index) => {
											return (
											<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-6 tm-zindex-0" key={`clg${index}`}>
												<div className="vc_column-inner  ">
													<div className="wpb_wrapper">
														<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
															{collegeloop && collegeloop.length > 0 && collegeloop.map((college, index2) => {
															return (
															<li key={`clgi${college.id}`}>
																<i className="tm-skincolor fa fa-arrow-circle-right"></i>
																<span className="tm-list-li-content">
																	<b>
																		<Link to={`/colleges/${college.slug}`}>
																		{college.college}</Link>
																	</b>
																</span>
															</li>
															)
															})}
														</ul>
													</div>
												</div>
											</div>
											)
											})}      
										</div>
									</div>
								</div>
							</div>
						</div>
				  </div>
				  <div className="vc_row-full-width vc_clearfix"></div>
						<div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293975 tm-responsive-custom-10696870 vc_row-has-fill break-991-colum tm-zindex-0 tm-textcolor-white tm-bgimage-position-left_center tm-bg tm-bgimage-yes">
							<div className="tm-row-wrapper-bg-layer tm-bg-layer"></div>
							<div className="vc_row container vc_row-o-equal-height vc_row-flex">
								<div className="wpb_column tm-column vc_column_container vc_col-sm-12 vc_col-has-fill tm-zindex-2 hidden-lg hidden-md hidden-sm visible-xs">
									<div className="vc_column-inner vc_custom_1567664749067 tm-col-bgimage-yes tm-responsive-custom-94735818">
										<div className="tm-col-wrapper-bg-layer tm-bg-layer tm-bgimage-position-center_center">
											<div className="tm-bg-layer-inner"></div>
										</div>
										<div className="wpb_wrapper eligibility-img">
											<img src="/assets/img/eligibility.jpg"/>
										</div>
									</div>
								</div>
								<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-overlap-row tm-span tm-left-span tm-zindex-2 margin-15px-991-colum">
									<div className="vc_column-inner vc_custom_1567664250316 tm-responsive-custom-75919887">
										<div className="wpb_wrapper">
											<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567774236927 tm-responsive-custom-64911954 tm-zindex-0">
												<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid tm-bgcolor-skincolor vc_custom_1567774428913 tm-responsive-custom-71053255 tm-zindex-0">
													<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-10 tm-zindex-0">
														<div className="vc_column-inner vc_custom_1567658690491 ">
															<div className="wpb_wrapper">
																<h2 style={myStyle} className="tm-custom-heading vc_custom_1567661288254" >Eligibility criteria</h2>
															</div>
														</div>
													</div>
												</div>
												<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
													<div className="vc_column-inner tm-responsive-custom-12751771">
														<div className="wpb_wrapper eligibility-list">
															<p style={{marginTop:"20px"}}>The students interested to take <b>bpt admission in Bangalore</b> should qualify the following eligibility criteria:</p>
															<ul className="tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591385700">
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">The candidates belonging to general or unreserved category should have scored a minimum aggregate of 50% or equivalent in PUC or 10+2 education for joining the Bachelor of Physiotherapy course.</span></li>
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">The candidates belonging to SC/ST/OBC category should have scored a minimum aggregate of 45% or equivalent in PUC or 10+2 education (or equivalent) for joining the Bachelor of Physiotherapy course.</span></li>
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">The candidates should have completed 17 years of age on 31st December of the year of admission.</span></li>
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">The candidate should have passed any other examination conducted by Boards/ Councils/ Intermediate examination established by State Government/ Central Government and recognized as equivalent to Pre-University Examination by recognised state board and central board.</span></li>
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">The candidates should have completed a Pre-University course with Vocational Physiotherapy as their optional subject.</span></li>
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">The candidates should have pursued Science stream with Physics, Chemistry, Biology and English as their main subjects.</span></li>
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">Eligibility in national/ state/ institution qualifying examinations.</span></li>
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">Lateral Entry into 2nd year of the 4.5-years BPT course for candidates who have completed the DPT course conducted by the Paramedical Board.</span></li>
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">The candidate should submit to the Principal of the Institution a certificate of Medical Fitness from an authorized Government Medical Officer.</span></li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="wpb_column tm-column vc_column_container vc_col-sm-10 vc_col-has-fill tm-zindex-2 hidden-xs">
										<div className="vc_column-inner vc_custom_1567664749067 tm-col-bgimage-yes tm-responsive-custom-94735818">
											<div className="tm-col-wrapper-bg-layer tm-bg-layer tm-bgimage-position-center_center">
												<div className="tm-bg-layer-inner"></div>
											</div>
											<div className="wpb_wrapper eligibility-img">
												<img src="/assets/img/eligibility.jpg"/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
				  <div className="vc_row-full-width vc_clearfix"></div>
				  <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom" style={{backgroundColor:"#f0f0f0"}}>
					 <div className="vc_row container">
						<div className="wpb_column tm-column vc_column_container vc_col-sm-6 tm-zindex-0 margin-15px-991-colum">
						   <div className="vc_column-inner tm-responsive-custom-12751771">
							  <div className="wpb_wrapper">
								 <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
									<section className="tm-vc_cta3-container tm-sepcolor-default">
									   <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
										  <div className="tm-vc_cta3_content-container">
											 <div className="tm-vc_cta3-content">
												<header className="tm-vc_cta3-content-header tm-wrap">
												   <div className="tm-vc_cta3-headers tm-wrap-cell">
													  <h5 className="tm-custom-heading ">Affiliations and Approval</h5>
												   </div>
												   <div className="heading-seperator"><span></span></div>
												</header>
											 </div>
										  </div>
									   </div>
									</section>
								 </div>
								 <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
									<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
									   <div className="vc_column-inner  ">
										  <div className="wpb_wrapper">
											 <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
											  	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content"><b>Government of Karnataka</b></span></li>
            								  	<p>The Bachelor of Physiotherapy (BPT) course offered by colleges in Bangalore city should be approved by the Government of Karnataka.</p>
            								  	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content"><b>Rajiv Gandhi University of Health Sciences (RGUHS)</b></span></li>
            								  	<p>Rajiv Gandhi University of Health Sciences is a government central university in Bangalore established in 1996 by the Government of Karnataka and recognized by the University Grants Commission (UGC) for the regulation of higher education in health sciences in Karnataka. The university is the largest health sciences university in India and is located in Jayanagar.</p>
            								  	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content"><b>Indian Association of Physiotherapy (IAP)</b></span></li>
            								  	<p>The Indian Association of Physiotherapy (IAP) is a non-governmental, credential and assessment organization registered under the Indian Society Act that represents the common interests of Indian physiotherapists and their patients at a national and international level.</p>
											 </ul>
										  </div>
									   </div>
									</div>
								 </div>
							  </div>
						   </div>
						</div>
						<div className="wpb_column tm-column vc_column_container vc_col-sm-5 vc_col-sm-offset-1 tm-zindex-0 margin-15px-991-colum" id="notifications">
						   <div className="vc_column-inner tm-responsive-custom-12751771">
							  <div className="wpb_wrapper">
								 <hr className="hidden-sm visible-xs"/>
								 <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
									<section className="tm-vc_cta3-container tm-sepcolor-default">
									   <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
										  <div className="tm-vc_cta3_content-container">
											 <div className="tm-vc_cta3-content">
												<header className="tm-vc_cta3-content-header tm-wrap">
												   <div className="tm-vc_cta3-headers tm-wrap-cell">
													  <h5 className="tm-custom-heading "><span className="bell fa fa-bell"></span> Notifications</h5>
												   </div>
												   <div className="heading-seperator"><span></span></div>
												</header>
											 </div>
										  </div>
									   </div>
									</section>
								 </div>
								 <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
									<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
									   <div className="vc_column-inner  ">
										  <div className="wpb_wrapper">
											 <ul className="m-0 tm-recent-post-list scrollable-notification">
												{notifications && notifications.length > 0 && notifications.map((notification, index) => {
												return (
												<li className="tm-recent-post-list-li" key={`n${notification.id}`}>
												   <Link to={`/notifications/${notification.slug}`}>
												   <img width="150" height="150" src={`/storage/notifications/${notification.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image" alt={notification.title} data-loaded="true"/></Link>
												   <Link to={`/notifications/${notification.slug}`}>
												   {notification.title}</Link>
												   <span className="post-date">{this.notificationDate(new Date(notification.updated_at))}</span>
												</li>
												);
												})}
											 </ul>
										  </div>
									   </div>
									</div>
								 </div>
							  </div>
						   </div>
						</div>
					 </div>
				  </div>
				  <div className="vc_row-full-width vc_clearfix"></div>
				  <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293976 tm-responsive-custom-10696870 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-left_center tm-bg tm-bgimage-yes">
					 <div className="tm-row-wrapper-bg-layer tm-bg-layer"></div>
					 <div className="vc_row container vc_row-o-equal-height vc_row-flex">
						<div className="wpb_column tm-column vc_column_container vc_col-sm-8 col-sm-offset-2 tm-overlap-row tm-span tm-left-span tm-zindex-2 margin-15px-991-colum">
						   <div className="apply-now">
							  <div className="widget">
								 <h4 className="widget-title text-center">Apply now</h4>
								 <p className="text-center">Apply now to get admission for BPT</p>
								 <div className="widget-content">
									<form onSubmit={this.handleSubmit} id="home-page-middle-form" autoComplete="off">
									   <div className="row">
										  <div className="col-xs-12 col-sm-6">
											 <div className="text-block">
												<input type="text" name="name" placeholder="Name *" required/> 
											 </div>
										  </div>
										  <div className="col-xs-12 col-sm-6">
											 <div className="text-block">
												<input type="email" name="email" placeholder="Email *" required/> 
											 </div>
										  </div>
										  <div className="col-xs-12 col-sm-6">
											 <div className="text-block">
												<input type="text" name="phone" value={mobile} onChange={e => this.updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}  className="EnquiryMobile" placeholder="Phone *" required /> 
											 </div>
										  </div>
										  <div className="col-xs-12 col-sm-6">
											 <div className="text-block">
												<input type="text" name="course" placeholder="Course *" required/>
												<input type="hidden" name="source" value="2"/> 
											 </div>
										  </div>
										  <div className="col-xs-12 col-sm-6">
											 <div className="text-block">
												<Select options={countries} name="country"  placeholder="Select Country" required onChange={this.selectCountry} />
											 </div>
										  </div>
										  <div className="col-xs-12 col-sm-6">
											 <div className="text-block">
												<Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={this.selectRegion} />
											 </div>
										  </div>
										  <div className="col-xs-12 col-sm-6 text-center hidden-xs">
											 <input onClick={this.resetForm} type="button" value="Reset" className="btn clear-btn"/> 
										  </div>
										  <div className="col-xs-12 col-sm-6 text-center">
											 <input type="submit" value="Submit" className="btn submit"/> 
										  </div>
									   </div>
									</form>
								 </div>
							  </div>
						   </div>
						</div>
					 </div>
				  </div>
				  <div className="vc_row-full-width vc_clearfix"></div>
				  <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom">
					 <div className="vc_row container">
						<div className="wpb_column tm-column vc_column_container vc_col-sm-6 tm-zindex-0 margin-15px-991-colum">
						   <div className="vc_column-inner tm-responsive-custom-12751771">
							  <div className="wpb_wrapper">
								 <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
									<section className="tm-vc_cta3-container tm-sepcolor-default">
									   <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
										  <div className="tm-vc_cta3_content-container">
											 <div className="tm-vc_cta3-content">
												<header className="tm-vc_cta3-content-header tm-wrap">
												   <div className="tm-vc_cta3-headers tm-wrap-cell">
													  <h5 className="tm-custom-heading ">Blogs</h5>
												   </div>
												   <div className="heading-seperator"><span></span></div>
												</header>
											 </div>
										  </div>
									   </div>
									</section>
								 </div>
								 <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
									{blogs && blogs.length > 0 && blogs.map((blogloop, index) => {
									return (
									<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-6 tm-zindex-0" key={`b${index}`}>
									   <div className="vc_column-inner  ">
										  <div className="wpb_wrapper">
											 <ul className="m-0 tm-recent-post-list">
												{blogloop && blogloop.length > 0 && blogloop.map((blog, index2) => {
												return (
												<li className="tm-recent-post-list-li" key={`bl${blog.id}`}>
												   <Link to={`/blogs/${blog.slug}`}>
												   <img width="150" height="150" src={`/storage/blogs/${blog.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image" alt={blog.title}/></Link>
												   <Link to={`/blogs/${blog.slug}`}>
												   {this.truncate(blog.title)}</Link>
												   <span className="post-date">{this.notificationDate(new Date(blog.updated_at))}</span>
												</li>
												)
												})}
											 </ul>
										  </div>
									   </div>
									</div>
									)
									})}
								 </div>
							  </div>
						   </div>
						</div>
						<div className="wpb_column tm-column vc_column_container vc_col-sm-5 vc_col-sm-offset-1 tm-zindex-0 margin-15px-991-colum">
						   <div className="vc_column-inner tm-responsive-custom-12751771">
							  <div className="wpb_wrapper">
								 <hr className="hidden-sm visible-xs"/>
								 <div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
									<section className="tm-vc_cta3-container tm-sepcolor-default">
									   <div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
										  <div className="tm-vc_cta3_content-container">
											 <div className="tm-vc_cta3-content">
												<header className="tm-vc_cta3-content-header tm-wrap">
												   <div className="tm-vc_cta3-headers tm-wrap-cell">
													  <h5 className="tm-custom-heading ">Similar Trending courses</h5>
												   </div>
												   <div className="heading-seperator"><span></span></div>
												</header>
											 </div>
										  </div>
									   </div>
									</section>
								 </div>
								 <div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
									<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
									   <div className="vc_column-inner  ">
										  <div className="wpb_wrapper">
											 <ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
												{courses && courses.length > 0 && courses.map((course, index) => {
												return (
												<li key={`cs${course.id}`}>
												   <i className="tm-skincolor fa fa-arrow-circle-right"></i>  
												   <span className="tm-list-li-content">
													  <Link to={course.url} target="_blank">
													  {course.course}</Link>
												   </span>
												</li>
												)
												})}
											 </ul>
										  </div>
									   </div>
									</div>
									<div className="col-sm-10 text-center">
									   <hr/>
									   <Link to="/other-pages/master-of-physiotherapy-mpt"><button className="btn-course" style={{marginBottom:"0px"}}><i className="fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>MPT</b> (Master of Physiotherapy)</span> </button></Link>
									</div>
								 </div>
							  </div>
						   </div>
						</div>
					 </div>
				  </div>
				  <div className="vc_row-full-width vc_clearfix"></div>
				  	<div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684293977 tm-responsive-custom-10696870 vc_row-has-fill break-991-colum tm-zindex-0 tm-textcolor-white tm-bgimage-position-bottom_center tm-bg tm-bgimage-yes">
						<div className="tm-row-wrapper-bg-layer tm-bg-layer"></div>
							<div className="vc_row container vc_row-o-equal-height vc_row-flex">
								<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-overlap-row tm-span tm-left-span tm-zindex-2 margin-15px-991-colum">
									<div className="vc_column-inner vc_custom_1567664250317 tm-responsive-custom-75919887">
										<div className="wpb_wrapper">
											<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
												<section className="tm-vc_cta3-container tm-sepcolor-default">
													<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
														<div className="tm-vc_cta3_content-container">
															<div className="tm-vc_cta3-content">
																<header className="tm-vc_cta3-content-header tm-wrap">
																	<div className="tm-vc_cta3-headers tm-wrap-cell">
																		<h5 className="tm-custom-heading ">Why should one choose to <br className="hidden-xs"/>study BPT in Bangalore?</h5>
																	</div>
																	<div className="heading-seperator"><span></span></div>
																</header>
															</div>
														</div>
													</div>
												</section>
											</div>
											<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
												<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
													<div className="vc_column-inner">
														<div className="wpb_wrapper study-list">
															<p>The <b>BPT course in Bangalore</b> is a popular undergraduate healthcare professional course in Bangalore. It is an interesting course choice because:</p>
															<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">There are plenty of career opportunities in leading hospitals and healthcare centres in Bangalore.</span></li>
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">The candidates can make a career out of helping and caring for people.</span></li>
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">Physiotherapy allows a candidate to work with their hand and learn about human anatomy.</span></li>
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">Bangalore encourages many sports and outdoor activities and the BPT course provides a lot of opportunities to learn new techniques in the subject area of movement and neurology.</span></li>
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">BPT graduates get to perform many jobs related to the treatment of patients under the direction of qualified and trained physiotherapists who are recognized in Bangalore.</span></li>
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">There are diverse and popular physiotherapy specialties in Bangalore that focus on medical areas including Geriatrics, Paediatrics, Orthopaedics, Neurological Disorders and Sports Medicine.</span></li>
																<li><i className="tm-skincolor fa fa-circle"></i>  <span className="tm-list-li-content">Hospitals and clinics in Bangalore provide a very good salary package with additional benefits.</span></li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="wpb_column tm-column vc_column_container vc_col-sm-6 vc_col-has-fill tm-zindex-2 hidden-xs">
										<div className="vc_column-inner vc_custom_1567664749067 tm-col-bgimage-yes tm-responsive-custom-94735818">
											<div className="tm-col-wrapper-bg-layer tm-bg-layer tm-bgimage-position-center_center">
												<div className="tm-bg-layer-inner"></div>
											</div>
											<div className="wpb_wrapper study-img">
												<img src="/assets/img/study-bpt.png"/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
	
				  	<div className="vc_row-full-width vc_clearfix"></div>
						  <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567684117259 tm-responsive-custom-55915355 vc_row-has-fill break-991-colum tm-zindex-0 tm-bgimage-position-right_bottom" style={{backgroundColor:"#f0f0f0"}}>
								<div className="vc_row container">
									<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
										<div className="vc_column-inner tm-responsive-custom-12751771">
											<div className="wpb_wrapper">
												<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
													<section className="tm-vc_cta3-container tm-sepcolor-default">
														<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
															<div className="tm-vc_cta3_content-container">
																<div className="tm-vc_cta3-content">
																	<header className="tm-vc_cta3-content-header tm-wrap">
																		<div className="tm-vc_cta3-headers tm-wrap-cell">
																			<h5 className="tm-custom-heading">Career Scope for BPT</h5>
																		</div>
																		<div className="heading-seperator"><span></span></div>
																	</header>
																</div>
															</div>
														</div>
													</section>
												</div>
												<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
													<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
														<div className="vc_column-inner  ">
															<div className="wpb_wrapper">
																<p>The students pursuing physiotherapy in the <b>top physiotherapy colleges in Bangalore</b> can find ample job opportunities in the field of physiotherapy. Physiotherapy graduates get plenty of opportunities in top hospitals and healthcare centres with attractive salary packages. The course provides equal opportunities in both Government and Private sectors. Some of the top fitness centres in India also attract the best talents to train their clients appropriately.</p>
																<p><b>Physiotherapists can find plenty of opportunities in a number of professional areas including:</b></p>
																<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Hospitals</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Nursing Homes</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Assisted living and Residential facilities</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Private Practices</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Home Healthcare Centres</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Childcare Centres</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Veterans Affairs Hospitals</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Clinics</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Industrial Units</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Independent practice as a Freelancer</span></li>
																</ul>
																<br/>
																<p><b>There is an incredible scope for physiotherapists. This includes:</b></p>
																<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Graduates can continue to develop their expertise and skills through specializations in diverse health areas.</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">There is a very high demand for qualified physiotherapists in India and abroad.</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Candidates can pursue management courses and join the Administrative sector.</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Graduates can become Teachers and Lecturers in health universities and colleges.</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Graduates can either become Clinicians or Researchers by joining research organizations, which will enable candidates to develop their knowledge as well.</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">There are plenty of private and government jobs with a very good pay scale.</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">There is a heavy demand for qualified, trained and skilled physiotherapists in countries such as the US, UK, Canada and Australia.</span></li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							<div className="vc_row container">  
								<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
									<div className="vc_column-inner tm-responsive-custom-12751771">
										<div className="wpb_wrapper">
											<hr/>
											<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
												<section className="tm-vc_cta3-container tm-sepcolor-default">
													<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
														<div className="tm-vc_cta3_content-container">
															<div className="tm-vc_cta3-content">
																<header className="tm-vc_cta3-content-header tm-wrap">
																	<div className="tm-vc_cta3-headers tm-wrap-cell">
																		<h5 className="tm-custom-heading">BPT Placements and Salary Package</h5>
																	</div>
																	<div className="heading-seperator"><span></span></div>
																</header>
															</div>
														</div>
													</div>
												</section>
											</div>
											<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
												<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
													<div className="vc_column-inner  ">
														<div className="wpb_wrapper">
															<p>The students studying physiotherapy in the <b>best physiotherapy colleges in Bangalore</b> can easily find excellent job roles. Many top educational institutes in Bangalore have excellent Placement Departments that provide multiple placement opportunities for BPT graduates. There is an incredible career scope for skilled and qualified physiotherapists. This includes:</p>
															<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Project and Internship opportunities in government and private hospitals, nursing homes, clinics and healthcare centres</span></li>
																<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Opportunity as a Teacher, Professor or Lecturer in government or private colleges and universities</span></li>
																<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Physiotherapist in healthcare centres, fitness centres, community centres and rehabilitation centres</span></li>
																<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Rehabilitator in fitness centres, community centres and mental health centres</span></li>
																<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Sports Therapist during state-level and national sports events</span></li>
																<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Chiropractor in healthcare and rehabilitation centres</span></li>
																<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Research Associate in research and scientific facilities</span></li>
																<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Exercise Therapist in fitness and rehabilitation centres</span></li>
															</ul>
															<br/>
															<p>Graduates can continue to develop their expertise and skills through additional specializations in diverse health areas.</p>
															<p>The average salary package for BPT graduates is around 2 LPA to 7 LPA. Graduates can begin their career as Trainees and Assistants and proceed to become Senior Physiotherapists and Rehabilitators.</p>
															<p><b>The salary for BPT graduates varies with:</b></p>
															<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Work exposure in diverse specializations</span></li>
																<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Work experience</span></li>
																<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Higher level of education</span></li>
																<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Additional certifications</span></li>
																<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Professional skills</span></li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="vc_row container">
								<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
									<div className="vc_column-inner tm-responsive-custom-12751771">
										<div className="wpb_wrapper">
											<hr/>
											<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
												<section className="tm-vc_cta3-container tm-sepcolor-default">
													<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
														<div className="tm-vc_cta3_content-container">
															<div className="tm-vc_cta3-content">
																<header className="tm-vc_cta3-content-header tm-wrap">
																	<div className="tm-vc_cta3-headers tm-wrap-cell">
																		<h5 className="tm-custom-heading">Top BPT Recruitment Companies in Bangalore</h5>
																	</div>
																	<div className="heading-seperator"><span></span></div>
																</header>
															</div>
														</div>
													</div>
												</section>
											</div>
											<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
												<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
													<div className="vc_column-inner  ">
														<div className="wpb_wrapper">
															<p>BPT graduates have plenty of career opportunities in government and private academic institutions, healthcare centres, fitness centres, clinics, community centres, nursing homes, sports facilities and rehabilitation centres.</p>
															<p><b>Popular recruiters of BPT graduates are:</b></p>
															<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-4 tm-zindex-0">
																<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Schools/Colleges</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Research Institutions</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Narayana Hrudayalaya</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Apollo Hospital</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Fortis Hospital</span></li>
																</ul>
															</div>
															<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-4 tm-zindex-0">
																<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Hosmat Hospital</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Help Age India</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Practo</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Talwalkars Pantaloon Fitness Private Limited</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Zelus Healthcare Private Limited</span></li>
																</ul>
															</div>
															<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-4 tm-zindex-0">
																<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Qi Lifecare Private Limited</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Klaxon India</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">ReLiva Physiotherapy & Rehab</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Portea Medical</span></li>
																	<li><i className="tm-skincolor fa fa-arrow-circle-right"></i>  <span className="tm-list-li-content">Hospimedica International</span></li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
				 
				  <div className="vc_row-full-width vc_clearfix"></div>
				  <div data-vc-full-width="true" data-vc-full-width-init="false" className="tm-row wpb_row vc_row-fluid vc_custom_1567685146842 tm-zindex-0 tm-bgcolor-skincolor tm-bgimage-position-center_center" style={{backgroundColor:"rgb(41 61 122)"}}>
				  <div className="vc_row container">
					 <div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0">
						<div className="vc_column-inner  ">
						   <div className="wpb_wrapper">
							  <div className="wpb_raw_code wpb_content_element wpb_raw_html vc_custom_1568379925491 tm-textcolor-white">
								 <div className="wpb_wrapper">
									<h5 style={{textAlign:"center",fontSize:"29px",lineHeight:"33px",fontWeight:"400"}} className="tm-custom-heading">
									   <i className="fa fa-graduation-cap" aria-hidden="true"></i> Get Direct BPT Admission Now, <u><Link to="#enquiry" data-toggle="modal">Click here</Link></u>
									</h5>
								 </div>
							  </div>
						   </div>
						</div>
					 </div>
				  </div>
			   </div>
			   <div className="vc_row-full-width vc_clearfix"></div>
			</div>
		</article>
	</main>
</div>
</div>
</div>
</div>
        );
    }
}

// export default Home;