import React from "react";
import {Link} from "react-router-dom";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useState, useEffect, Component } from 'react';
import Helmet from 'react-helmet';
import Select from 'react-select'
import countryFile from './Country';
import checkNumber from './CheckMobileNumber';

export default class Syllabus extends Component {
    
    constructor(props){
        super(props);
        this.state={
          blogs:[],
          notifications:[],
          reviews:[],
		  countries: [], 
		  states: [],
		  otherpages:[],
		  stateValue:'',
		  mobile:''
        }
      }

	  selectCountry = (val) => {
		this.setState({ stateValue: '' });
		const state_arr = countryFile.s_a[val.key].split("|"); 
		const stateArray = [];
		stateArray.push({
			value: '', 
			label: 'Select'
		});
		for (var i = 0; i < state_arr.length; i++) {
			stateArray.push({
			value: state_arr[i], 
			label: state_arr[i]
			});
		}
		this.setState({ states: stateArray });
		var Country = val.value;
        let check = checkNumber.checkNumber(Country,this.state.mobile);
        if(check === 2){
          this.setState({mobile:''});
        }
	  }
	
	  selectRegion = (val) => {
		if(val.value !== '' && val.value !== null){
			this.setState({stateValue:val});
		} else {
			this.setState({stateValue:''});
		}
	  }

	  updateMobile = (value) => {
		this.setState({mobile:value});
	  }
     
      componentDidMount() {
		const countryArray = [];
        var country1 = countryFile.country_arr;
        countryArray.push({
          value: '', 
          label: 'Select',
          key:[0]
        });
        for (var i = 0; i < country1.length; i++) {
          countryArray.push({
            value: country1[i], 
            label: country1[i],
            key:[i+1]
          });
        }
        this.setState({countries:countryArray});
		this.getUser();
      }
      //API CALLS
      getList=()=>{
        this.getUser();
      }

      async getUser(){
        let usersData=await axios.get('/syllabus-page-data')
          .then(res=>{
          //   return res.data;
            this.setState({
              blogs:res.data.blogs,
              notifications:res.data.notifications,
              reviews:res.data.collegerates,
  			  otherpages:res.data.otherpages
            },()=>{
            })
          })
          .catch(err=>{
            console.log(err);
          })
      }

      handleSubmit(event) {
        // console.log(event.target.country.value);
        event.preventDefault();
        jQuery("#overlay").fadeIn(300);　
        const formData = new FormData(event.target);
        console.log(formData);
        axios.post('/store-enquiry', formData)
        .then(({data})=>{
            event.target.reset()
            if(data.status === 200){
                Swal.fire({
                    icon:"success",
                    text:data.message
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else if(data.status === 201){
                Swal.fire({
                    text:'We have already received an enquiry from this mobile number or email.',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            } else {
                Swal.fire({
                    text:'Error please try again later!',
                    icon:"error"
                })
                setTimeout(function(){
                  jQuery("#overlay").fadeOut(300);
                },500);
            }
        })
      }

	  notificationDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

	  blogDate(par){
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const today = new Date(par);
        const month = months[today.getMonth()];
        const year = today.getFullYear();
        const date = today.getDate();
        return month+ ' ' +date+', ' +year;
      }

    render(){
        const {blogs}=this.state;
        const {notifications}=this.state;
        const {reviews}=this.state;
		const {otherpages}=this.state;
		const { countries, states, stateValue, mobile } = this.state;
        return (
          <div id="content-wrapper" className="site-content-wrapper">
            <Helmet>
                <title>Syllabus | BPT Colleges in Bangalore</title>
            </Helmet>
              <div id="content" className="site-content container">
                    <div id="content-inner" className="site-content-inner row multi-columns-row">
					  <div id="primary" className="content-area col-md-8 col-lg-8 col-xs-12 sub">
						 <main id="main" className="site-main">
                         <div className="row">
									<div className="wpb_column tm-column vc_column_container vc_col-sm-12 tm-zindex-0 margin-15px-991-colum">
										<div className="vc_column-inner tm-responsive-custom-12751771">
											<div className="wpb_wrapper">
												<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
													<section className="tm-vc_cta3-container tm-sepcolor-default">
														<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
															<div className="tm-vc_cta3_content-container">
																<div className="tm-vc_cta3-content">
																	<header className="tm-vc_cta3-content-header tm-wrap">
																		<div className="tm-vc_cta3-headers tm-wrap-cell">
																			<h3 className="tm-custom-heading ">BPT Syllabus</h3> </div>
																		<div className="heading-seperator"><span></span></div>
																	</header>
																	<div className="tm-cta3-content-wrapper">
																		<p>The Bachelor of Physiotherapy (BPT) course is a four-years course that includes six months of compulsory Internship in leading hospitals or clinical facilities integrated with the college.</p>
																		<p>The course includes theoretical classes and practical sessions related to Allied Healthcare subjects. Clinical exposure is provided in leading multispecialty hospitals in Bangalore. The course curriculum includes the following subjects:</p>
																	</div>
																</div>
															</div>
														</div>
													</section>
												</div>
												<div className="tm-row-inner vc_row wpb_row vc_inner vc_row-fluid vc_custom_1567683876377 break-991-colum tm-zindex-0">
													<div className="wpb_column tm-column-inner vc_column_container vc_col-sm-12 tm-zindex-0">
														<div className="vc_column-inner  ">
															<div className="wpb_wrapper syllabus-collapse">
																<div  className="vc_toggle vc_toggle_simple vc_toggle_color_white  vc_toggle_size_md vc_toggle_active">
																	<div className="vc_toggle_title"><h6>Semester I</h6><i className="vc_toggle_icon"></i></div>
																	<div className="vc_toggle_content">
																		<ul className="tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Anatomy</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Physiology</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Biochemistry</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Basic Nursing</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">English</span></li>
																		</ul>
																	</div>
																</div>
																<div  className="vc_toggle vc_toggle_simple vc_toggle_color_white  vc_toggle_size_md">
																	<div className="vc_toggle_title"><h6>Semester II</h6><i className="vc_toggle_icon"></i></div>
																	<div className="vc_toggle_content">
																		<ul className="tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Biomechanics</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Psychology</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Sociology</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Orientation to Physiotherapy</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Integrated Seminar/Problem-Based Learning (PBL) Sessions</span></li>
																		</ul>
																	</div>
																</div>
																<div  className="vc_toggle vc_toggle_simple vc_toggle_color_white  vc_toggle_size_md">
																	<div className="vc_toggle_title"><h6>Semester III</h6><i className="vc_toggle_icon"></i></div>
																	<div className="vc_toggle_content">
																		<ul className="tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Pathology</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Microbiology</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Pharmacology</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">First Aid & CPR</span></li>
																		</ul>
																	</div>
																</div>
																<div  className="vc_toggle vc_toggle_simple vc_toggle_color_white  vc_toggle_size_md">
																	<div className="vc_toggle_title"><h6>Semester IV</h6><i className="vc_toggle_icon"></i></div>
																	<div className="vc_toggle_content">
																		<ul className="tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Exercise Therapy</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Electrotherapy</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Research Methodology and Biostatistics</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Constitution of India</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Introduction to Treatment</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Clinical Observation Posting</span></li>
																		</ul>
																	</div>
																</div>
																<div  className="vc_toggle vc_toggle_simple vc_toggle_color_white  vc_toggle_size_md">
																	<div className="vc_toggle_title"><h6>Semester V</h6><i className="vc_toggle_icon"></i></div>
																	<div className="vc_toggle_content">
																		<ul className="tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">General Medicine</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">General Surgery</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Orthopedics and Traumatology</span></li>
																		</ul>
																	</div>
																</div>
																<div  className="vc_toggle vc_toggle_simple vc_toggle_color_white  vc_toggle_size_md">
																	<div className="vc_toggle_title"><h6>Semester VI</h6><i className="vc_toggle_icon"></i></div>
																	<div className="vc_toggle_content">
																		<ul className="tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Orthopedics and Sports Physiotherapy</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Cardio-Respiratory & General Physiotherapy</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Supervised Rotatory Clinical Training - I</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Allied Therapies</span></li>
																		</ul>
																	</div>
																</div>
																<div  className="vc_toggle vc_toggle_simple vc_toggle_color_white  vc_toggle_size_md">
																	<div className="vc_toggle_title"><h6>Semester VII</h6><i className="vc_toggle_icon"></i></div>
																	<div className="vc_toggle_content">
																		<ul className="tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Neurology and Neurosurgery</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Community Medicine</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Neuro-Physiology</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Community-based Rehabilitation</span></li>
																		</ul>
																	</div>
																</div>
																<div  className="vc_toggle vc_toggle_simple vc_toggle_color_white  vc_toggle_size_md">
																	<div className="vc_toggle_title"><h6>Semester VIII</h6><i className="vc_toggle_icon"></i></div>
																	<div className="vc_toggle_content">
																		<ul className="tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Ethics, Administration and Supervision</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Evidence-based Physiotherapy and Practice</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Supervised Rotatory Clinical Training - II</span></li>
																			<li><i className="tm-skincolor fa fa-circle"></i> <span className="tm-list-li-content">Project</span></li>
																		</ul>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<hr/>
												<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default" id="internship">
													<section className="tm-vc_cta3-container tm-sepcolor-default">
														<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
															<div className="tm-vc_cta3_content-container">
																<div className="tm-vc_cta3-content">
																	<header className="tm-vc_cta3-content-header tm-wrap">
																		<div className="tm-vc_cta3-headers tm-wrap-cell">
																			<h3 className="tm-custom-heading ">Internship</h3> </div>
																		<div className="heading-seperator"><span></span></div>
																	</header>
																	<div className="tm-cta3-content-wrapper">
																		
																		<p>The BPT colleges in Bangalore offer a compulsory Internship program for six months which begins after the candidate clears all the subjects in the final examinations.</p>
																		<p>The Internship has more value when performed in the teaching hospital recognized and associated with the respective university. There is plenty of scope for graduates in:</p>
																		<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Government hospitals</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Private hospitals</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Nursing homes</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Clinics</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Fitness centres</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Sports facilities</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Healthcare centres</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Community centres</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Rehabilitation centres</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Research and scientific facilities</span></li>
																		</ul>
																		<br/>
																		<p><b>The Internship helps to provide all-round development of the graduates and is very important because:</b></p>
																		<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">It provides more clinical exposure in diverse specializations</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">It helps to increase work experience</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">It helps to provide hands-on and physical training</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">It provides an opportunity to acquire additional certifications</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">It increases career opportunities during Campus Recruitment</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">It helps to establish and maintain reliable professional networks</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">It helps to develop professional skills</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">It helps in the smooth transition into the professional field as trained and qualified Physiotherapists, Rehabilitators, Chiropractors and Therapists</span></li>
																		</ul>
																		<p>In addition, graduates can continue to develop their expertise and skills through additional specializations in various health areas during the Master of Physiotherapy (MPT) course.</p>
																	</div>
																</div>
															</div>
														</div>
													</section>
												</div>
												<hr/>
												<div className="tm-element-heading-wrapper tm-heading-inner tm-element-align-left tm-seperator-solid tm-heading-style-vertical  vc_custom_1567595169829 tm-sepcolor-default">
													<section className="tm-vc_cta3-container tm-sepcolor-default">
														<div className="tm-vc_general tm-vc_cta3 tm-cta3-only tm-vc_cta3-style-classic tm-vc_cta3-shape-rounded tm-vc_cta3-align-left tm-vc_cta3-color-transparent tm-vc_cta3-icon-size-md tm-vc_cta3-actions-no tm-cta3-with-desc tm-cta3-rev-heading">
															<div className="tm-vc_cta3_content-container">
																<div className="tm-vc_cta3-content">
																	<header className="tm-vc_cta3-content-header tm-wrap">
																		<div className="tm-vc_cta3-headers tm-wrap-cell">
																			<h3 className="tm-custom-heading ">Admission Process</h3> </div>
																		<div className="heading-seperator"><span></span></div>
																	</header>
																	<div className="tm-cta3-content-wrapper">
																		
																		<p>There are many Government and Private colleges and universities in Bangalore that offer the BPT course. These colleges are:</p>
																		<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Approved by the Government of Karnataka</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Affiliated to Rajiv Gandhi University of Health Sciences (RGUHS)</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">Approved by the Indian Association of Physiotherapists (IAP)</span></li>
																		</ul>
																		<br/>
																		<p><b>The admission process in these colleges and universities in Bangalore for BPT course is of two types:</b></p>
																		<ul className="m-0 tm-list tm-list-style-icon tm-list-icon-color-skincolor tm- tm-icon-skincolor tm-list-textsize-medium tm-list-icon-library-fontawesome vc_custom_1567591038413">
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Merit Admission: </b>Candidates seeking admission to colleges affiliated to Rajiv Gandhi University of Health Sciences (RGUHS) can undergo the counselling conducted by the Karnataka Examination Authority (KEA) through Karnataka CET Exam (KCET).</span></li>
																			<li><i className="tm-skincolor fa fa-arrow-circle-right"></i> <span className="tm-list-li-content"><b>Management Admission: </b>Candidates seeking for Management seats can go for Direct Admission through Management Quota.</span></li>
																		</ul>
																	</div>
																</div>
															</div>
														</div>
													</section>
												</div>
											</div>
										</div>
									</div>
								</div>
						 </main>
					  </div>
					  <aside id="sidebar-right" className="widget-area col-md-4 col-lg-4 col-xs-12 sidebar">
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title">Notifications</h3>
							<ul className="p-10 tm-recent-post-list scrollable-list">
								{notifications && notifications.length > 0 && notifications.map((notification, index) => {
                        			return (
										<li className="tm-recent-post-list-li" key={`n${notification.id}`}>
											<Link to={`/notifications/${notification.slug}`}>
												<img width="150" height="150" src={`/storage/notifications/${notification.image2}`} alt=""/>
											</Link>
											<Link to={`/notifications/${notification.slug}`}>{notification.title}</Link>
											<span className="post-date">{this.blogDate(new Date(notification.updated_at))}</span>
										</li>
								    );
							    })}
							</ul>
						 </aside>
                         <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							{otherpages && otherpages.length > 0 && otherpages.map((otherpage, index) => {
                                return (
							<Link to={`/other-pages/${otherpage.slug}`} key={`o${otherpage.id}`}><button className="btn-course" style={{marginBottom:"0"}}><i className="fa fa-arrow-circle-right"></i> <span className="tm-list-li-content">{otherpage.title}</span> </button></Link>
								)
							})}
						 </aside>
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor: '#131d3b'}}>Blogs</h3>
							<ul className="p-10 tm-recent-post-list scrollable-list">
								{blogs && blogs.length > 0 && blogs.map((blog, index) => {
									return (
									<li className="tm-recent-post-list-li" key={`bl${blog.id}`}>
										<Link to={`/blogs/${blog.slug}`}><img width="150" height="150" src={`/storage/blogs/${blog.image2}`} className="attachment-thumbnail size-thumbnail wp-post-image" alt={blog.title}/></Link>
										<Link to={`/blogs/${blog.slug}`}>{blog.title}</Link>
										<span className="post-date">{this.notificationDate(new Date(blog.updated_at))}</span>
									</li>
									)
								})}
							</ul>
						 </aside>
						 <aside id="categories-5" className="rating widget-even widget-10 widget widget_categories">
							<h3 className="widget-title" style={{backgroundColor: '#479507'}}>Reviews & Ratings of BPT Colleges in Bangalore</h3>
							<div className="rate-box">
							{reviews && reviews.length > 0 && reviews.map((review, index) => {
								const oneStar = (review.rating >= 1) ?  'fa fa-star' :  'fa fa-star-o' ;
								const twoStar = (review.rating >= 2) ?  'fa fa-star' :  'fa fa-star-o' ;
								const threeStar = (review.rating >= 3) ?  'fa fa-star' :  'fa fa-star-o' ;
								const fourStar = (review.rating >= 4) ?  'fa fa-star' :  'fa fa-star-o' ;
								const fiveSar = (review.rating >= 5) ?  'fa fa-star' :  'fa fa-star-o' ;
								return (
									<div className="recent-box text-left" key={`n${review.id}`}>
										<div className="recent">
										<h6><span><div className="rating-stars">
											<ul id="">
												<li className="star rate-this" title="1 star" data-value="1" style={{margin: 0}}>
												<i className={oneStar}></i>
												</li>
												<li className="star rate-this" title="2 stars" data-value="2" style={{margin: 0}}>
												<i className={twoStar}></i>
												</li>
												<li className="star rate-this" title="3 stars" data-value="3" style={{margin: 0}}>
												<i className={threeStar}></i>
												</li>
												<li className="star rate-this " title="4 stars" data-value="4" style={{margin: 0}}>
												<i className={fourStar}></i>
												</li>
												<li className="star rate-this " title="5 stars" data-value="5" style={{margin: 0}}>
												<i className={fiveSar}></i>
												</li>
											</ul> 
										</div></span> <span>|</span> {review.name} <span>|</span> 
											<Link to={`/colleges/${review.url}`}>{review.college}</Link> <span>|</span> <span>{review.date}</span></h6>
										<p>{review.review}</p>
										</div>
									</div>
								);
                    		})}
							</div>
						 </aside>
						 <aside id="themetechmount-recent-posts-5" className="widget-odd widget-9 widget themetechmount_widget_recent_entries">
							<h3 className="widget-title" style={{backgroundColor: '#479507'}}>Apply now</h3>
							<div className="widget-content p-10 side-form">
								<form onSubmit={this.handleSubmit} className="course-form clearfix">
									<div className="row">
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="name" placeholder="Name *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="email" name="email" placeholder="Email *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" value={mobile} onChange={e => this.updateMobile(e.target.value)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}} className="EnquiryMobile" name="phone" placeholder="Phone *" required/> </div>
										</div>
										<div className="col-xs-12">
											<div className="text-block">
												<input type="text" name="course" placeholder="Course *" required/>
												<input type="hidden" name="source" value="2"/> </div>
										</div>
										<div className="col-xs-12 pad-5">
											<div className="text-block">
                          						<Select options={countries} name="country"  placeholder="Select Country" required onChange={this.selectCountry} />
											</div>
										</div>
                    					<div className="col-xs-12 pad-5">
											<div className="text-block">
                          						<Select options={states} value={stateValue} name="state" className="select-state" placeholder="Select State" required onChange={this.selectRegion} />
											</div>
										</div>
										<div className="col-xs-12 text-center">
											<input type="submit" value="Submit" className="btn submit"/> </div>
									</div>
								</form>
							</div>
						 </aside>
						 <aside id="acadevo_contact_widget-4" className="widget-even widget-14 widget acadevo_contact_widget">
					        <Link className="hover" to="https://bcomcollegesinbangalore.com/" target="_blank"><h3 className="widget-title" style={{backgroundColor: '#134054'}}>Click to know about B.Com Colleges in Bangalore <i className="fa fa-arrow-circle-right" aria-hidden="true"></i></h3></Link>
				         </aside>
					  </aside>
				   </div>
              </div>
        </div>
        );
    }
}

// export default Home;